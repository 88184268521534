export const chronicConditions = {
  ANXIETY_OR_DEPRESSION: 'anxiety-or-depression',
  DIABETES: 'diabetes',
  MOBILITY: 'difficulty-with-walking',
  HIGH_BLOOD_PRESSURE_OR_HEART_DISEASES: 'high-blood-pressure-or-heart-diseases',
  HIGH_CHOLESTEROL: 'high-cholesterol',
  JOINT_PAIN: 'joint-pain',
  OVERWEIGHT: 'overweight',
  OTHER: 'other-chronic-condition',
  CHRONIC_CONDITIONS_KEY: 'chronic-conditions',
};

export const CHRONIC_CONDITIONS_KEY_PATH_MAP: {
  [key: string]: string;
} = {
  anxiety_or_depression: chronicConditions.ANXIETY_OR_DEPRESSION,
  'diabetes_or_pre-diabetes': chronicConditions.DIABETES,
  difficulty_with_walking: chronicConditions.MOBILITY,
  high_blood_pressure_or_heart_diseases: chronicConditions.HIGH_BLOOD_PRESSURE_OR_HEART_DISEASES,
  high_cholesterol: chronicConditions.HIGH_CHOLESTEROL,
  joint_pain: chronicConditions.JOINT_PAIN,
  overweight: chronicConditions.OVERWEIGHT,
  other: chronicConditions.OTHER,
  extra_message: chronicConditions.OTHER,
};
