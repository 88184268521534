<div>
  <nav *ngIf="isLoggedIn; else burgerMenu" class="user-logged-in">
    <mighty-profile-picture [src]="profilePhoto" [name]="userName"></mighty-profile-picture>
    <mighty-icon icon="arrow_down" [color]="iconColor"></mighty-icon>
  </nav>
  <ng-template #burgerMenu>
    <div class="menu-burger" [class.open]="show">
      <div class="line line-top"></div>
      <div class="line line-middle"></div>
      <div class="line line-bottom"></div>
    </div>
  </ng-template>
</div>
