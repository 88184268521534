import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JoinService {
  joinSubscription: Subject<void> = new Subject<void>();
  context: 'community' | 'lesson' = 'community';

  constructor() {}

  show() {
    this.joinSubscription.next();
  }
  subscribe(next: () => void) {
    this.joinSubscription.subscribe(next);
  }
  setContext(ctx: 'community' | 'lesson') {
    this.context = ctx;
  }
  getContext() {
    return this.context;
  }
}
