import { inject, Injectable } from '@angular/core';
import { ExperimentService } from '../utils/experiment.service';

export enum ModaVariant {
  DEFAULT = 'default',
  REDUCED = 'reduced',
}

@Injectable({
  providedIn: 'root',
})
export class ModaExperimentService {
  private experimentService = inject(ExperimentService);
  public readonly EXPERIMENT_KEY = 'moda-experiment';
  private readonly VARIANTS = [ModaVariant.DEFAULT, ModaVariant.REDUCED];

  async startExperiment(): Promise<ModaVariant> {
    try {
      const variant = await this.experimentService.startExperiment(this.EXPERIMENT_KEY, this.VARIANTS);

      return variant as ModaVariant;
    } catch (error) {
      console.error('Error starting scheduling experiment:', error);
      return ModaVariant.DEFAULT;
    }
  }

  getVariant(): ModaVariant {
    return this.experimentService.getVariant(this.EXPERIMENT_KEY, this.VARIANTS) as ModaVariant;
  }
}
