import * as Sentry from '@sentry/angular-ivy';
import { Injectable, Optional } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';
import {
  Auth,
  ConfirmationResult,
  OAuthProvider,
  RecaptchaVerifier,
  User,
  authState,
  signInAnonymously,
  signInWithPhoneNumber,
  signInWithPopup,
  signOut,
} from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { OnboardingService } from './onboarding.service';
import { GetPhoneConfirmationException, PhoneConfirmationFailedException } from './exceptions/AuthExceptions';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<User | null> | undefined;
  phoneConfirmation!: ConfirmationResult;

  constructor(
    @Optional() private auth: Auth,
    private storageService: StorageService,
    private onboardingService: OnboardingService
  ) {
    if (auth) {
      this.user$ = authState(this.auth);
      authState(this.auth).subscribe(state => {
        if (!state) {
          this.user$ = undefined;
        } else {
          this.user$ = authState(this.auth);
        }
      });
    }
  }

  getAuth() {
    return this.auth;
  }

  // Possible errors: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_14
  async getPhoneConfirmation(phone: string, recaptcha: RecaptchaVerifier) {
    try {
      if ((window as any)?.hasOwnProperty('Cypress')) {
        this.auth.settings.appVerificationDisabledForTesting = true;
        const rec = new RecaptchaVerifier(this.auth, 'recaptcha', {});
        this.phoneConfirmation = await signInWithPhoneNumber(this.auth, phone, rec);
        return;
      }

      this.phoneConfirmation = await signInWithPhoneNumber(this.auth, phone, recaptcha);
    } catch (err: any) {
      let errorMessage: string | undefined;

      if (err instanceof FirebaseError) {
        errorMessage = {
          'auth/captcha-check-failed': 'Please, try again a few minutes.',
          'auth/invalid-phone-number': 'The phone number provided is not valid. Please provide a valid phone number.',
          'auth/missing-phone-number': 'Phone number is required.',
        }[err.code];
      } else {
        errorMessage =
          'An error occurred during your phone confirmation. Please enter your cell phone number and try again.';
      }
      Sentry.captureException(new PhoneConfirmationFailedException('Phone confirmation failed.', err));
      throw new Error(errorMessage);
    }
  }

  // Possible errors: https://firebase.google.com/docs/reference/js/v8/firebase.auth.ConfirmationResult#error-codes
  async confirmPhoneVerificationCode(verificationCode: string) {
    try {
      await this.phoneConfirmation.confirm(verificationCode);
    } catch (err: any) {
      let errorMessage: string | undefined;

      if (err instanceof FirebaseError) {
        errorMessage = {
          'auth/invalid-verification-code': 'The verification code you provided is not valid.',
          'auth/missing-verification-code': 'Verification code was not provided.',
        }[err.code];
      } else {
        errorMessage = 'Unexpected error on code verification.';
      }
      Sentry.captureException(new GetPhoneConfirmationException('Error getting confirmation code.', err));
      throw new Error(errorMessage);
    }
  }

  // Possible errors: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_15
  async oAuthLogin(p: 'google.com' | 'facebook.com' | 'apple.com') {
    try {
      const provider = new OAuthProvider(p);
      await signInWithPopup(this.auth, provider);
    } catch (error) {
      console.log('AuthService ~ oAuthLogin ~ error', error);
      const provider = p.substring(0, p.indexOf('.'));
      throw new Error(
        `Unexpected error trying to sign in with ${provider.charAt(0).toUpperCase() + provider.slice(1)}`
      );
    }
  }

  getUser(): Observable<User | null> {
    if (!this.user$) {
      this.user$ = authState(this.auth);
    }
    return this.user$;
  }

  getToken() {
    return this.auth.currentUser?.getIdToken();
  }

  subscribe(next: (value: User | null) => void) {
    if (!this.user$) {
      this.user$ = this.getUser();
    }
    return this.user$.subscribe(next);
  }

  async logout() {
    console.log('🚀 ~ AuthService ~ logout ~ logout');
    this.onboardingService.resetOnboarding();
    this.onboardingService.resetAllOnboarding();
    await signOut(this.auth);
  }

  async anonymousAuth(): Promise<void> {
    await signOut(this.auth);
    await signInAnonymously(this.auth)
      .then(async result => {
        console.log('You have been successfully authenticated!', result);
      })
      .catch(async e => {
        console.log('AuthLogin Error: ', e);
      });
    return;
  }
}
