import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Event } from '@mightyhealth/library';
import { AuthService } from 'src/app/utils/auth.service';
import { LoginService } from 'src/app/utils/login.service';

@Component({
  selector: 'mighty-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent {
  @Input() overlay = { show: true, closeOnClick: true };
  @Input() modalState = false;
  @Output() modalStateChange = new EventEmitter<boolean>();

  @Output() logged = new EventEmitter<boolean>();

  @Input()
  event?: Event;

  phoneConfirmation = false;

  constructor(private authService: AuthService, private loginService: LoginService) {}

  closeModal() {
    this.modalState = false;
    this.modalStateChange.emit(this.modalState);
    this.overlay.closeOnClick = true;
  }

  updateLoginState(e: boolean) {
    this.overlay.closeOnClick = false;
    this.phoneConfirmation = e;
  }

  loggedState(e: boolean) {
    this.overlay.closeOnClick = true;
    this.loginService.setLogged(e);
    this.logged.emit(e);
  }
}
