export interface Journalist {
  firstName: string;
  path: string;
  link: string;
}

export const journalists: Journalist[] = [
  {
    firstName: 'Stephanie',
    path: 'stephaniedolgoff',
    link: 'https://player.vimeo.com/video/851740678',
  },
  {
    firstName: 'Julie',
    path: 'juliekendrick',
    link: 'https://player.vimeo.com/video/851734694',
  },
  {
    firstName: 'Emma',
    path: 'emmakershaw',
    link: 'https://player.vimeo.com/video/851734203',
  },
  {
    firstName: 'Alexa',
    path: 'alexamellardo',
    link: 'https://player.vimeo.com/video/851697007',
  },
  {
    firstName: 'Amber',
    path: 'ambersayer',
    link: 'https://player.vimeo.com/video/851733838',
  },
  {
    firstName: 'Jennifer',
    path: 'jenniferwalsh',
    link: 'https://player.vimeo.com/video/851733481',
  },
  {
    firstName: '',
    path: 'press',
    link: 'https://player.vimeo.com/video/852900371',
  },
];
