import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Member, TrackingEvent } from '@mightyhealth/library';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MembersService } from './members.service';

@Injectable({
  providedIn: 'root',
})
export class KlaviyoService {
  url = environment.KLAVIYO_CONFIG.url;
  publicApiKey = environment.KLAVIYO_CONFIG.publicApiKey;

  constructor(private http: HttpClient, private memberService: MembersService) {}

  async identify(member: Member) {
    try {
      const profile = {
        token: this.publicApiKey,
        properties: {
          $id: member.id,
          web: true,
          memberId: member.id,
          $email: member?.email ? member?.email : member?.unverifiedEmail,
          $first_name: member?.name ? member?.name.trim().split(' ')[0] : '',
          $phone_number: member?.phone ? member?.phone : member?.unverifiedPhone,
          $consent: ['email', 'web', 'sms', 'directmail', 'mobile'],
          sms_consent: true,
          onboarded: member?.onboarded,
          introducedToCoach: member?.introducedToCoach,
          subscription: member?.subscription,
          latestLoginProvider: member?.latestLoginProvider,
          lastSeen: member?.lastSeen,
          appInfo: member?.appInfo,
          referralLink: member?.referralLink,
          timeZone: member?.timeZone,
          birthday: member?.birthday,
          signUpDate: member?.signUpDate,
          goal: member?.goal?.goal,
          reason: member?.goal?.reason,
          goalJson: member?.goal,
        },
      };
      const encodedString = btoa(JSON.stringify(profile));
      const result = await lastValueFrom(
        this.http.get<number>(`${this.url}/identify`, {
          params: { data: encodedString },
          headers: {
            Accept: 'text/html',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
      );

      return result;
    } catch (e) {
      console.error('🚀 ~ KlaviyoService ~ identify ~ e:', e);
      return e;
    }
  }

  async addTraits(properties: any) {
    try {
      const profile = {
        token: this.publicApiKey,
        properties,
      };
      // properties.$id = this.memberService.getUserId();

      const encodedString = btoa(JSON.stringify(profile));
      const result = await lastValueFrom(
        this.http.get<number>(`${this.url}/identify`, {
          params: { data: encodedString },
          headers: {
            Accept: 'text/html',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
      );

      return result;
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async track(eventName: TrackingEvent | string, options = {}) {
    try {
      const member = await this.memberService.getCurrentMember();
      if (member) {
        const trackingEvent = {
          token: this.publicApiKey,
          event: eventName,
          properties: options,
          customer_properties: { $id: member.id },
        };

        const encodedString = btoa(JSON.stringify(trackingEvent));

        const result = await firstValueFrom(
          this.http.get<number>(`${this.url}/track`, {
            params: { data: encodedString },
            headers: {
              Accept: 'text/html',
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
        );

        return result;
      }

      return;
    } catch (e) {
      console.error(e);
      return e;
    }
  }
}
