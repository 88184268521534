import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject, Subject, first } from 'rxjs';
import { MembersService } from '../apis/members.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private showLogin: ReplaySubject<boolean> = new ReplaySubject(1);
  private logged: Subject<boolean> = new Subject();

  constructor(private router: Router, private membersService: MembersService, private toastService: ToastService) {}

  async openModal() {
    this.showLogin.next(true);
  }

  async closeModal() {
    this.showLogin.next(false);
  }

  async showSubscribe(next: (value: boolean) => void) {
    return this.showLogin.subscribe(next);
  }

  async setLogged(logged: boolean) {
    this.logged.next(logged);
    try {
      if (logged) {
        await this.membersService.me();
        await this.router.navigate(['/home']);
      } else {
        console.log('Not logged');
      }
    } catch (e) {
      await this.toastService.addToast({
        message: this.handleErrorMessage(e as Error),
        type: 'error',
      });
    }
  }

  async subscribe(next: (value: boolean) => void) {
    return this.logged.subscribe(next);
  }

  subscribeOnce(next: (value: boolean) => void) {
    return this.logged.pipe(first()).subscribe(next);
  }

  private handleErrorMessage(error: HttpErrorResponse | Error) {
    let errorMessage = error.message;

    if (error instanceof HttpErrorResponse) {
      errorMessage =
        {
          401: 'Please, check your credentials or retry login method.',
          403: 'Invalid credentials. Please, register before logging in.',
        }[error.status] ?? 'An error ocurred. Please, try again.';
    }
    return errorMessage;
  }
}
