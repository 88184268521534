import { Injectable } from '@angular/core';
import { MembersService } from '../apis/members.service';
import { LandingHomePageVariantsNames } from '../pages/home/home.variants';
import { StorageService } from './storage.service';
import { TrackerService } from './tracking/tracker.service';

@Injectable({
  providedIn: 'root',
})
export class ExperimentService {
  constructor(
    private storage: StorageService,
    private tracker: TrackerService,
    private memberService: MembersService
  ) {}

  public async startExperiment(
    experiment: string,
    fixedVariants: string[],
    forcedVariants?: string[]
  ): Promise<string> {
    const variant =
      forcedVariants && Array.isArray(forcedVariants) && forcedVariants.length > 0
        ? this.getVariant(experiment, forcedVariants)
        : this.getVariant(experiment, fixedVariants);

    await this.tracker.identify(await this.memberService.getCurrentMember(), {
      [`experiment-${experiment}`]: variant,
    });

    this.storage.set(experiment, variant);
    return variant;
  }

  public getVariant(experiment: string, variants: string[]): string {
    let variant = this.storage.get(experiment);

    return variants.some(v => v === variant) ? variant : this.randomize(variants);
  }

  private randomize(variants: string[]): string {
    return variants[Math.floor(Math.random() * variants.length)];
  }
}
