import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MightyComponentsModule } from '@mightyhealth/angular-web-components';

@Component({
  standalone: true,
  selector: 'mighty-invalid-scheduling-link-component',
  templateUrl: './invalid-scheduling-link-page.component.html',
  styleUrls: ['./invalid-scheduling-link-page.component.scss', '../scheduling-container.component.scss'],
  imports: [MightyComponentsModule],
})
export class InvalidSchedulingLinkPageComponent {
  private router = inject(Router);

  navigateHome() {
    this.router.navigate(['']);
  }
}
