import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../utils/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, private router: Router) {}

  handleError(error: Error) {
    console.log('🚀 ~ TokenInterceptor ~ handleError ~ error', error);
    if (error instanceof HttpErrorResponse && error.status === 403) {
      console.log('🚀 ~ TokenInterceptor ~ handleError ~ === 403');
      this.auth.logout();
      return throwError(() => error);
    }
    if (error instanceof HttpErrorResponse && error.status !== 401) {
      console.log('🚀 ~ TokenInterceptor ~ handleError ~ !== 401');
      return throwError(() => error);
    } else {
      console.log('🚀 ~ TokenInterceptor ~ handleError ~ else');
      this.auth.logout();
      this.router.navigate(['/']);
      return throwError(() => error);
    }
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.hasMightyDomain(request) && this.shouldOverride(request)) {
      return this.auth
        .getUser()
        .pipe(first())
        .pipe(catchError(e => this.handleError(e)))
        .pipe(
          mergeMap(async (user: User | null) => {
            return await user?.getIdToken();
          })
        )
        .pipe(
          mergeMap((idToken: string | undefined) => {
            const authRequest = this.addToken(idToken ? idToken : null, request);
            return next.handle(authRequest).pipe(catchError(e => this.handleError(e)));
          })
        );
    } else {
      return next.handle(request);
    }
  }

  private addToken(idToken: string | null, request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: idToken ? `Firebase ${idToken}` : '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  private shouldOverride(request: HttpRequest<any>): boolean {
    return !request.headers.get('Authorization');
  }

  private hasMightyDomain(request: HttpRequest<any>): boolean {
    return (
      request?.urlWithParams.startsWith(environment.API_URL) || request?.url.startsWith(environment.API_UNAUTH_URL)
    );
  }
}
