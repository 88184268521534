<footer>
  <div class="columns">
    <div class="column navigation">
      <img class="logo" src="/webapp-assets/logo-v2.svg" alt="mighty-heath-logo" height="71" />
      <ul>
        <li *ngIf="!enterprise && !member" class="body-2" (click)="openLogin()">
          <a href="#">Try for Free</a>
        </li>
        <li *ngIf="enterprise" class="body-2">
          <a href="https://mightyhealth.typeform.com/to/bGz0JxRj" target="_blank">Request a Demo</a>
        </li>
        <li *ngIf="!member" class="body-2" style="cursor: pointer">
          <a (click)="navigate()">For Individuals</a>
        </li>
        <li class="body-2" style="cursor: pointer">
          <a routerLink="/about-us">About Us</a>
        </li>
        <li class="body-2"><a target="_blank" href="https://mightyhealth.com/blog">Blog</a></li>
        <li class="body-2">
          <a target="_blank" href="https://mightyhealth.com/blog/mighty-health-faq">FAQ</a>
        </li>
        <li class="body-2">
          <a target="_blank" href="https://mightyhealth.com/blog/memberships-amp-billing">Memberships and Billing</a>
        </li>
        <li class="body-2">
          <a href="mailto:support@mightyhealth.com">Contact Us</a>
        </li>
        <li class="body-2">
          <a target="_blank" href="https://mightyhealth.com/privacy-policy.html">Privacy Policy</a>
        </li>
      </ul>
    </div>
    <div class="column stores">
      <a class="store" href="https://play.google.com/store/apps/details?id=com.mightyhealth.app" target="_blank">
        <img height="50" width="169" src="/webapp-assets/google-play.png" alt="google-play-store-badge" />
      </a>
      <a class="store" href="https://apps.apple.com/us/app/mighty-better-heart-health/id1441145050" target="_blank">
        <img height="50" width="145" src="/webapp-assets/app-store.svg" alt="app-store-badge" />
      </a>
    </div>
  </div>

  <div class="tiny">
    Copyright {{ year() }} Mighty Technologies, Inc.<br />
    All rights reserved.
  </div>
</footer>
