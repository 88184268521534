import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: Storage | undefined;

  constructor() {
    try {
      this.storage = (globalThis as any).window?.localStorage;
    } catch (e) {
      console.log('Failed to access localStorage');
    }
  }

  set(key: string, value: any) {
    try {
      this.storage?.setItem(key, this.toStorable(value));
    } catch (error) {
      console.log(`Error storing ${value}`);
    }
  }

  get(key: string) {
    const data: any = this.storage?.getItem(key);

    if (!data) return null;

    try {
      return JSON.parse(data);
    } catch (error) {
      return data;
    }
  }

  clear() {
    this.storage?.clear();
  }

  private isObject(value: any) {
    return Array.isArray(value) || typeof value === 'object';
  }

  private toStorable(value: string | number | boolean | object) {
    let parsed: string = '';

    if (this.isObject(value)) {
      return (parsed = JSON.stringify(value));
    }

    if (typeof value === 'number') {
      return Number(value).toString();
    }

    return value.toString();
  }
}
