import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Member } from '@mightyhealth/library';
import { MembersService } from 'src/app/apis/members.service';
import { LoginService } from 'src/app/utils/login.service';

@Component({
  selector: 'mighty-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class FooterComponent implements OnInit {
  enterprise = false;
  member: Member | undefined;
  constructor(private loginService: LoginService, private router: Router, private memberService: MembersService) {}

  ngOnInit() {
    if (this.router.url.includes('enterprise')) {
      this.enterprise = true;
    }
    this.memberService.subscribe(member => {
      this.member = member;
    });
  }

  openLogin() {
    this.loginService.openModal();
  }

  navigate(route = '/') {
    this.router.navigate([route]);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  year() {
    return new Date().getFullYear();
  }
}
