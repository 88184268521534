import { Component, Input } from '@angular/core';

@Component({
  selector: 'mighty-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.css'],
})
export class ContextMenuComponent {
  @Input() userName: string | undefined = '';
  @Input() profilePhoto: string | undefined = '';
  @Input() show: boolean = false;
  @Input() isLoggedIn: boolean = false;
  @Input() color: string = '#415364';
  @Input() iconColor: string = 'gray_darkest';
}
