import { inject, Injectable } from '@angular/core';
import { ExperimentService } from '../utils/experiment.service';

export enum OptumValidationVariant {
  OPTUM_INFO_SLIDE = 'optum-code-info',
}

@Injectable({
  providedIn: 'root',
})
export class OptumExperimentService {
  private experimentService = inject(ExperimentService);
  public readonly SCHEDULING_EXPERIMENT_KEY = 'optum-validation';

  private readonly VARIANTS = [OptumValidationVariant.OPTUM_INFO_SLIDE];

  async startExperiment(): Promise<OptumValidationVariant> {
    try {
      const variant = await this.experimentService.startExperiment(this.SCHEDULING_EXPERIMENT_KEY, this.VARIANTS);

      return variant as OptumValidationVariant;
    } catch (error) {
      console.error('Error starting scheduling experiment:', error);
      return OptumValidationVariant.OPTUM_INFO_SLIDE;
    }
  }

  getVariant(): OptumValidationVariant {
    return this.experimentService.getVariant(this.SCHEDULING_EXPERIMENT_KEY, this.VARIANTS) as OptumValidationVariant;
  }
}
