import { RecaptchaVerifier } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaFactoryService {
  constructor(private authService: AuthService) {}

  create(containerOrId: string = 'recaptcha') {
    return new RecaptchaVerifier(this.authService.getAuth(), containerOrId, { size: 'invisible' });
  }
}
