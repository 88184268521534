import { Component, Injectable, Type } from '@angular/core';

export type OnboardingStep = {
  next: string | null;
  prev: string | null;
  path: string;
  position: number;
  // component: Type<any>;
};

export type OnboardingStepEvent = {
  step: OnboardingStep;
  timeout?: number;
};

@Injectable({
  providedIn: 'root',
})
export class OnboardingStepsService {
  constructor() {}

  getSteps(routes: string[]): OnboardingStep[] {
    return this.arrangeSteps(routes);
  }

  private arrangeSteps(routes: string[]): OnboardingStep[] {
    const steps: OnboardingStep[] = [];

    routes.forEach((path, currentIndex) => {
      const nextIndex = currentIndex + 1;
      const previousIndex = currentIndex - 1;

      let step: OnboardingStep = {
        next: null,
        prev: null,
        path,
        position: currentIndex,
      };

      if (routes[nextIndex]) {
        step.next = routes[nextIndex];
      }

      if (routes[previousIndex]) {
        step.prev = routes[previousIndex];
      }

      steps.push(step);
    });

    return steps;
  }
}
