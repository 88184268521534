import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Entitlements,
  MightySubscription,
  Subscription,
  SubscriptionGroup,
  SubscriptionStore,
  TrackingEvent,
} from '@mightyhealth/library';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import Stripe from 'stripe';
import { ImpactService } from '../utils/impact.service';
import { TrackerService } from '../utils/tracking/tracker.service';
import { MembersService } from './members.service';
import { SubscriptionEntitlement } from '@mightyhealth/library/src/interfaces/Member';

export interface SubscriptionPrices {
  annualSubscription: MightySubscription;
  price: string;
  monthlySubscription: MightySubscription;
  monthlyPrice: number;
  mightySubscriptions: MightySubscription[];
}

export interface PaymentInfo {
  paymentMethod: 'card' | 'google-pay' | 'apple-pay';
  id?: string;
  token?: string;
  promotionId?: string;
  promotionCode?: string;
  name?: string;
  email?: string;
  phone?: string;
  trialPeriodDays?: number;
  sourcePartner?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private subscriptionGroup = SubscriptionGroup.DEFAULT;

  constructor(
    private http: HttpClient,
    private eventTracker: TrackerService,
    private impactService: ImpactService,
    private membersService: MembersService
  ) {}

  setSubscriptionGroup(subscriptionGroup: SubscriptionGroup) {
    this.subscriptionGroup = subscriptionGroup;
  }

  async list(
    group: SubscriptionGroup = this.subscriptionGroup,
    store: SubscriptionStore = SubscriptionStore.STRIPE
  ): Promise<Subscription[]> {
    return await lastValueFrom(
      this.http.get<Subscription[]>(`${environment.API_URL}/subscriptions`, {
        params: { store, group },
      })
    )!;
  }

  async getPrices(): Promise<any[]> {
    return await lastValueFrom(this.http.get<any[]>(`${environment.API_URL}/subscriptions/stripe/prices`))!;
  }

  async getSubscriptions(): Promise<SubscriptionPrices> {
    let subscriptionsList = [];
    let subscriptionsPrice: any[] = [];

    [subscriptionsList, subscriptionsPrice] = await Promise.all([this.list(), this.getPrices()]);

    const mightySubscriptions = subscriptionsList
      .map(s => ({
        ...s,
        price: subscriptionsPrice.find(p => p.lookup_key === s.storeProductId)?.unit_amount / 100,
      }))
      .sort((a, b) => a.duration - b.duration) as MightySubscription[];

    const annualSubscription = mightySubscriptions[0];

    console.log(
      '🚀 ~ file: subscription.service.ts:87 ~ SubscriptionService ~ getSubscriptions ~ mightySubscriptions:',
      mightySubscriptions
    );
    return {
      mightySubscriptions,
      annualSubscription,
      price: (annualSubscription.price / 12).toFixed(3).slice(0, 5),
      monthlySubscription: mightySubscriptions[1],
      monthlyPrice: mightySubscriptions[1].price,
    };
  }

  async paySubscription(
    subscription: Subscription,
    paymentInfo: PaymentInfo
  ): Promise<Stripe.Response<Stripe.Subscription> | undefined> {
    console.log('🚀 ~ file: subscription.service.ts:90 ~ SubscriptionService ~ subscription:', subscription);
    console.log('🚀 ~ file: subscription.service.ts:90 ~ SubscriptionService ~ paymentInfo:', paymentInfo);

    const response = await this.http.post<Stripe.Response<Stripe.Subscription>>(
      `${environment.API_URL}/subscriptions/stripe/pay`,
      {
        subscription,
        paymentInfo,
      }
    );
    const value = await lastValueFrom(response);

    let price: { unit_amount: number | null; currency: string } | undefined;
    if (value?.items?.data && value.items.data.length > 0) {
      price = value.items.data[0].price;
    }

    this.trackEvent(TrackingEvent.PAYMENT_SUBSCRIBE_PAGE, value, price, paymentInfo.paymentMethod);
    this.trackEvent(TrackingEvent.START_TRIAL, value, undefined, paymentInfo.paymentMethod);
    this.impactService.sendConversion({
      customer: value.customer,
      promotionCode: paymentInfo.promotionCode,
    });

    return value;
  }

  private trackEvent(
    event: TrackingEvent,
    value: Stripe.Response<Stripe.Subscription> | undefined,
    price: { unit_amount: number | null; currency: string } | undefined,
    paymentMethod: string
  ) {
    this.eventTracker.track(event, {
      paymentMethod: paymentMethod,
      ...value,
      ...(event === TrackingEvent.PAYMENT_SUBSCRIBE_PAGE
        ? {
            ['$FIREBASE']: {
              ...value,
              value: price ? (price.unit_amount || 0) / 100 : undefined,
              currency: price?.currency,
            },
          }
        : {}),
    });
  }

  async isSubscribed(): Promise<boolean> {
    const member = await this.membersService.getCurrentMember();
    return !!member?.subscription?.subscribed;
  }

  async hasEntitlement(entitlement: Entitlements): Promise<boolean> {
    const member = await this.membersService.getCurrentMember();
    return !!member?.subscription?.entitlements?.[entitlement];
  }
}
