import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ExperimentService } from './experiment.service';

@Injectable()
export class ExperimentGuard implements CanActivate {
  static forExperiment(experimentName: string, experimentVariants: string[]) {
    return { data: { experimentName, experimentVariants }, canActivate: [ExperimentGuard] };
  }
  constructor(private router: Router, private experiment: ExperimentService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const variant = await this.experiment.startExperiment(
      route.data['experimentName'],
      route.data['experimentVariants']
    );

    if (variant) {
      this.router.navigate([route.parent?.url.toString(), variant], {
        queryParamsHandling: 'merge',
        queryParams: route.queryParams,
      });
    }

    return true;
  }
}
