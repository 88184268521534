import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { MembersService } from '../apis/members.service';
import { LoginService } from '../utils/login.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private membersService: MembersService, private loginService: LoginService) {}
  async canActivate(_: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const member = await this.membersService.getCurrentMember();
    if (member) {
      return true;
    } else {
      this.loginService.openModal();
      return false;
    }
  }
}
