import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { MembersService } from './members.service';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  constructor(private memberService: MembersService) {}

  init() {
    this.memberService.subscribe(member => {
      if (member && member.id) {
        Sentry.setUser({
          id: member.id,
          mightyId: member.id,
          email: member.email ? member.email : member.unverifiedEmail,
          phone: member.phone ? member.phone : member.unverifiedPhone,
        });
      }
    });
  }
}
