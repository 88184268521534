import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { CommunityMember, Member, TrackingEvent } from '@mightyhealth/library';
import { filter, forkJoin, map, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LazyLoadScriptService } from './apis/lazy-load-script.service';
import { MembersService } from './apis/members.service';
import { SentryService } from './apis/sentry.service';
import { HeaderTheme } from './components/header/header.component.constants';
import { AuthService } from './utils/auth.service';
import { JoinService } from './utils/join.service';
import { LoginService } from './utils/login.service';
import { MetaTagsService, MightyMetaTags } from './utils/meta-tags.service';
import { Toast, ToastService } from './utils/toast.service';
import { TrackerService } from './utils/tracking/tracker.service';

@Component({
  selector: 'mighty-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  referralMember?: CommunityMember;
  currentMember?: Member;
  currentToast?: Toast;
  showLogin?: boolean = false;
  showBanner = true;
  headerTheme: HeaderTheme = HeaderTheme.DEFAULT;
  showHeader: boolean = true;

  defaultMetaTags: MightyMetaTags = {
    title: 'Mighty Health',
    description: '#1 rated online program for losing weight, reducing joint pain, and improving mobility and strength',
    custom: [
      {
        property: 'keywords',
        content:
          'Health coach, telehealth, occupational therapy, joint friendly, low impact, exercise, personalized, nutrition, weight loss, healthy weight, stretching, balance, fall prevention, mobility, joint pain, seniors, older adults, home workouts, doctors, food journal, live classes, online program, chair yoga, aerobics',
      },
    ],
  };
  metaTags: MightyMetaTags = this.defaultMetaTags;

  constructor(
    private joinService: JoinService,
    private router: Router,
    private trackerService: TrackerService,
    private route: ActivatedRoute,
    private memberService: MembersService,
    private authService: AuthService,
    private toastService: ToastService,
    private loginService: LoginService,
    private loadService: LazyLoadScriptService,
    private sentryService: SentryService,
    private metaTagsService: MetaTagsService
  ) {}
  ngOnInit(): void {
    this.memberService.subscribe(member => {
      this.currentMember = member;
      this.trackerService.identify(member);
    });
    this.trackerService.identify();

    this.trackerService.addAnonymousTraits({
      production: environment.production,
    });

    this.memberService.getReferralMember(member => {
      this.referralMember = member;
    });

    this.toastService.subscribe(toast => {
      this.currentToast = toast;
    });

    this.loginService.showSubscribe(showLogin => {
      this.showLogin = showLogin;
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        switchMap(route => {
          return route.title;
        })
      )
      .subscribe(title => {
        this.trackerService.page(title);
      });

    this.router.events.pipe(filter(e => e instanceof NavigationEnd || e instanceof ActivationEnd)).subscribe(e => {
      if (e instanceof NavigationEnd) {
        let url = e as NavigationEnd;
        this.showHeader = this.shouldShowHeader(url.url);

        this.showBanner = this.shouldShowBanner(url.url);

        this.metaTagsService.cleanUp();
        this.metaTagsService.setMetaTags(this.metaTags);
      }

      if (e instanceof ActivationEnd) {
        this.headerTheme = e.snapshot.data['header'] ?? HeaderTheme.DEFAULT;
        this.metaTags = {
          ...this.defaultMetaTags,
          ...e.snapshot.data['metaTags'],
          custom: [...(this.defaultMetaTags.custom ?? []), ...(e.snapshot.data['metaTags']?.custom ?? [])],
        };
      }
    });

    forkJoin([
      this.loadService.loadScript('https://player.vimeo.com/api/player.js'),
      this.loadService.loadScript('//static.klaviyo.com/onsite/js/klaviyo.js?company_id=YkpiqZ'),
    ]);

    this.sentryService.init();
  }

  join() {
    this.joinService.show();
  }
  closeToast() {
    this.toastService.updateCurrentToast();
  }
  logout() {
    this.authService.logout();
  }
  login(success: boolean) {
    this.trackerService.track(TrackingEvent.LOGIN_STARTED);
    if (!success) {
      this.toastService.addToast({
        type: 'error',
        message: 'Failed to login, please try again.',
      });
    }
    this.trackerService.track(TrackingEvent.LOGIN_FINISHED);
    this.showLogin = false;
  }

  openPage(path: string) {
    window.open(path, '_blank');
  }

  shouldShowHeader(url: string): boolean {
    const headerHiddenPaths = [
      '/get-started',
      '/optum/hero',
      '/onboarding',
      '/chapter',
      '/marshfield',
      '/scan',
      '/moda-oebb',
      '/moda-pebb',
      '/providence-pebb',
      '/paywall',
      '/pre-scheduling',
      '/scheduling',
      '/book-appointment',
      '/invalid-scheduling-link',
    ];
    return !headerHiddenPaths.some(path => url.startsWith(path) || url.includes(path));
  }

  shouldShowBanner(url: string): boolean {
    const bannerHiddenPaths = [
      '/get-app',
      '/get-started',
      '/onboarding',
      '/home',
      '/activation-flow',
      '/paywall',
      '/chapter',
      '/booking-wall',
      '/pre-scheduling',
      '/scheduling',
    ];
    return !bannerHiddenPaths.some(path => url.startsWith(path) || url.includes(path));
  }
}
