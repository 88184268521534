<mighty-modal [show]="modalState" (close)="closeModal()" [flex]="true" [overlay]="overlay">
  <div class="modal-content">
    <div class="text-wrap" *ngIf="!phoneConfirmation">
      <div *ngIf="event" class="body-3">Reserve Spot</div>

      <h4>{{ event?.title }}</h4>
      <div class="body-3">
        {{ event?.date | date : 'EE, MMMM d, y, h:mm:ss a' }}
      </div>
    </div>

    <mighty-login
      (phoneConfimationFlow)="updateLoginState($event)"
      (modalStateChange)="closeModal()"
      (logged)="loggedState($event)">
    </mighty-login>
  </div>
</mighty-modal>
