<mighty-banner *ngIf="currentMember && !currentMember.onboarded && showBanner" (click)="openPage('/get-app')">
  <div class="banner-lg">
    <div>
      To see your personal health plan, track your progress, and join the community, install the Mighty Health app on
      your phone
    </div>
    <mighty-button style="margin-bottom: 0px" color="blue-mighty">Get App</mighty-button>
  </div>

  <div class="banner-sm">For the full experience, install the Mighty Health app</div>
</mighty-banner>

<mighty-join></mighty-join>

<mighty-header *ngIf="showHeader" [theme]="headerTheme"></mighty-header>

<div (click)="join()" class="referral-banner padding-content" *ngIf="referralMember">
  <div class="columns">
    <img *ngIf="referralMember?.photoUrl" [src]="referralMember.photoUrl" alt="member" />
    <div class="text">
      <div>
        <span class="capitalize">{{ referralMember.firstName }}</span> thought you might find this interesting - join
        them and thousands of others on the healthy living movement with Mighty Health
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
<mighty-toast
  [show]="this.currentToast?.show"
  [type]="this.currentToast?.type"
  [icon]="this.currentToast?.icon"
  (close)="closeToast()"
  *mightyRerender="this.currentToast"
  >{{ this.currentToast?.message }}</mighty-toast
>
<mighty-login-modal *ngIf="showLogin" [(modalState)]="showLogin" (logged)="login($event)"></mighty-login-modal>
