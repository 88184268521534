import { SwiperComponent } from 'swiper/angular';

export const Slides = {
  WELCOME: 'welcome',
  Q1: 'q1',
  Q2: 'q2',
  Q3: 'q3',
  Q5: 'q5',
  TESTIMONIAL: 'testimonial',
  PROGRAM_VALUE_01: 'program-value-01',
  PROGRAM_VALUE_02: 'program-value-02',
  TRANSITION_PROGRAM_VALUE_01_TO_Q3: 'transition-program-value-01-to-q3',
  TRANSITION_WELCOME_TO_Q1: 'transition-welcome-to-q1',
  TRANSITION_CREATING_HEATH_PLAN: 'transition-creating-health-plan',
  SPECIALIST_VIEW: 'specialist-view',
  SIGNUP: 'signup',
  HEAR_ABOUT_US: 'hear-about-us',
  HEALTH_INSURANCE: 'health-insurance',
};

export const SlidesHierarchy = [
  {
    slide: Slides.WELCOME,
    canVisit: [Slides.WELCOME],
  },
  {
    slide: Slides.Q1,
    canVisit: [Slides.WELCOME, Slides.Q1],
  },
  {
    slide: Slides.Q2,
    canVisit: [Slides.WELCOME, Slides.Q1, Slides.Q2],
  },
  {
    slide: Slides.Q3,
    canVisit: [Slides.WELCOME, Slides.Q1, Slides.Q2, Slides.Q3],
  },
  {
    slide: Slides.Q5,
    canVisit: [Slides.WELCOME, Slides.Q1, Slides.Q2, Slides.Q3, Slides.Q5],
  },
  {
    slide: Slides.SPECIALIST_VIEW,
    canVisit: [Slides.WELCOME, Slides.Q1, Slides.Q2, Slides.Q3, Slides.Q5, Slides.SPECIALIST_VIEW],
  },
  {
    slide: Slides.SIGNUP,
    canVisit: [Slides.WELCOME, Slides.Q1, Slides.Q2, Slides.Q3, Slides.Q5, Slides.SIGNUP],
  },
];

export const ONBOARDING_ORIGIN = 'onboarding_origin';
export const ONBOARDING_SKIPPED_SLIDE = 'onboarding_skipped_slide';
export const ONBOARDING_LAST_SLIDE_VISITED = 'onboarding_last_slide_visited';
export const ONBOARDING_COACH_SELECTED = 'onboarding_coach_selected';

export const SLIDE_TRANSITION_TIME_IN_MILLIS = {
  [Slides.TRANSITION_CREATING_HEATH_PLAN]: 7500,
};

export const findSlideIndexByClass = (
  className: string,
  swiperRef: SwiperComponent | undefined
): number | undefined => {
  const slideTo = swiperRef?.slidesEl.find(x => x.class === className) ?? undefined;
  return slideTo?.slideIndex;
};

export const tryToFindAndNavigate = (slideToFind: string, swiperRef: SwiperComponent | undefined) => {
  const slideTo = findSlideIndexByClass(slideToFind, swiperRef);
  if (slideTo || slideTo === 0) swiperRef?.swiperRef.slideTo(slideTo, 200);
};
