import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface Toast {
  show?: boolean;
  type?: string;
  message?: string;
  icon?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private currentToast: ReplaySubject<Toast | undefined> = new ReplaySubject(1);
  private lastToast: Toast = {};
  private toastList: Toast[] = [];

  async addToast(toast: Toast) {
    this.toastList.push(toast);
    if (!this.lastToast?.show) {
      this.updateCurrentToast();
    }
  }

  updateCurrentToast() {
    const nextToast = this.toastList.pop();
    this.closeToast();
    if (nextToast) {
      nextToast.show = true;
      this.lastToast = nextToast;
      this.currentToast.next(nextToast);
    }
  }

  closeToast() {
    const closedToast = { show: false };
    this.lastToast = closedToast;
    this.currentToast.next(closedToast);
  }

  async subscribe(next: (value: Toast | undefined) => void) {
    return this.currentToast.subscribe(next);
  }
}
