<div *ngIf="showing" class="card-wrapper">
  <div class="card">
    <div class="card-content">
      <video width="100%" autoplay loop playsinline webkit-playsinline>
        <source src="/webapp-assets/welcome.mp4" type="video/mp4" />
      </video>
      <h2>Join with our app!</h2>
      <p>
        {{
          context === 'community'
            ? 'Download our free app to join our community, take part in our monthly health challenges, and get your own personal health coach.'
            : 'Download our app to try out more free exercises, workouts, and live events. Get your very own health plan and personal coach today!'
        }}
      </p>

      <div *ngIf="!mobile" class="text-me">
        <div class="column stores">
          <a class="store" href="https://play.google.com/store/apps/details?id=com.mightyhealth.app" target="_blank">
            <img height="50" width="169" src="/webapp-assets/google-play-blue.svg" alt="google-play-store-badge" />
          </a>
          <a class="store" href="https://apps.apple.com/us/app/mighty-better-heart-health/id1441145050" target="_blank">
            <img height="50" width="145" src="/webapp-assets/app-store-blue.svg" alt="app-store-badge" />
          </a>
        </div>
      </div>

      <a *ngIf="mobile" (click)="openingLink()" target="_blank" href="https://mightyhealth.app.link/join-community">
        <mighty-button>{{ ios ? 'GO TO APP STORE' : 'GO TO PLAY STORE' }}</mighty-button>
      </a>
      <a class="skip" href="javascript:void(0)" (click)="hide()">Maybe later</a>
    </div>
  </div>
</div>
<div *ngIf="showing" class="background" (click)="hide()"></div>
