<ng-template [ngIf]="!phoneConfirmationTab" [ngIfElse]="confirmationTab">
  <div class="login-header">
    <h1>Welcome to Mighty Health</h1>
    <span>Enter your cell phone number</span>
  </div>

  <article class="login-form-group">
    <mighty-input
      type="tel"
      label="Cell phone"
      data-cy="phone-input"
      [dropSpecialCharacters]="true"
      [value]="phone"
      (valueChange)="onPhoneChange($event)">
    </mighty-input>
  </article>
  <mighty-button [disabled]="!isPhoneValid || loading" expand="full" (click)="sendPhoneVerificationCode()">
    <span>Continue</span>
  </mighty-button>
  <div class="register-here-text">
    Don't have an account? &nbsp;<span class="login-methods-btn" (click)="goToOnboarding()">Get started here</span>
  </div>
  <div *ngIf="!retryConfirmationCode" class="spacer">OR</div>
  <div
    *ngIf="!showLoginOptions && !retryConfirmationCode"
    class="login-methods-btn"
    (click)="renderLoginOptions()"
    data-cy="continue-different-method">
    Continue using a different method
  </div>

  <div *ngIf="!loading" class="social-login-wrapper" [class.show]="showLoginOptions && !retryConfirmationCode">
    <article class="btn-white" (click)="auth('apple.com')">
      <img src="/webapp-assets/logo-apple-black.svg" alt="apple-logo" />
      Continue with Apple
    </article>
    <article class="btn-white" (click)="auth('google.com')">
      <img src="/webapp-assets/logo-google.svg" alt="google-logo" />
      Continue with Google
    </article>
    <article class="btn-white" (click)="auth('facebook.com')">
      <img src="/webapp-assets/logo-facebook.svg" alt="facebook-logo" />
      Continue with Facebook
    </article>
  </div>
  <div *ngIf="loading" class="flex justify-center">
    <div [ngStyle]="{ marginTop: '20px' }" class="spinner-loader"></div>
  </div>
</ng-template>

<ng-template #confirmationTab>
  <img src="/webapp-assets/circle-phone.svg" />
  <h1 [ngStyle]="{ fontSize: '24px', lineHeight: '30px', marginTop: '25px' }">We just sent a code to your phone</h1>
  <p [ngStyle]="{ fontSize: '16px' }">
    Please enter the 6 digit code you received at<br />
    +1 ({{ phone.slice(0, 3) }}) {{ phone.slice(3, 6) }}-{{ phone.slice(6, 10) }}
  </p>
  <article class="login-form-group">
    <label class="login-form-group-label">Confirmation code</label>
    <input
      type="text"
      class="onboarding-form-group-input"
      placeholder="Must be 6-digits"
      maxlength="6"
      data-cy="code-verification-input"
      [(ngModel)]="confirmationCode" />
  </article>
  <mighty-button [disabled]="confirmationCode.length !== 6 || loading" expand="full" (click)="auth('phone')">
    <span data-cy="continue-button">Continue</span>
  </mighty-button>
  <div class="skip-step-btn" (click)="retryVerificationCodeSend()">I didn't get my code</div>
</ng-template>
<div #recaptchaContainer>
  <div id="recaptcha"></div>
</div>

<mighty-toast [show]="!!this.errorMessage" [type]="'error'"> {{ this.errorMessage }}</mighty-toast>
