import { inject, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { fetchConfig, RemoteConfig, getValue, isSupported } from '@angular/fire/remote-config';
import { environment } from '../../environments/environment';
import { fetchAndActivate } from '@firebase/remote-config';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private readonly FEATURE_FLAG_KEY: string = 'feature_flag';
  private readonly env: string = 'demo';

  private _remoteConfig: ReplaySubject<RemoteConfig> = new ReplaySubject(1);

  constructor(private injector: Injector, @Inject(PLATFORM_ID) private platformId: any) {
    this.env = environment.environmentName;
    isSupported().then((result: boolean) => {
      if (result) {
        const remoteConfig = this.injector.get(RemoteConfig);
        fetchAndActivate(remoteConfig).then(() => this._remoteConfig.next(remoteConfig));
      }
    });
  }

  public async getFeatureFlag(featureFlag: string): Promise<boolean> {
    if (await isSupported()) {
      return new Promise(async resolve => {
        const subscription = this._remoteConfig.subscribe(async remoteConfig => {
          try {
            const envFlags = await getValue(remoteConfig, `${this.FEATURE_FLAG_KEY}_${this.env}`);

            const parsedEnv = JSON.parse(envFlags.asString());

            resolve(parsedEnv[featureFlag]);
          } catch (e) {
            console.log(`Error while retrieving feature flag, env: ${this.env}, featureFlag: ${featureFlag}`);
            console.log(e);
            resolve(false);
          } finally {
            subscription?.unsubscribe();
          }
        });
      });
    }

    return false;
  }
}
