import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { Consent, MemberConsent, MemberConsentCreate } from '@mightyhealth/library';

@Injectable({
  providedIn: 'root',
})
export class MemberConsentsService {
  http = inject(HttpClient);

  async create(model: MemberConsentCreate): Promise<MemberConsent> {
    return lastValueFrom(this.http.post<MemberConsent>(`${environment.API_URL}/member_consents`, model));
  }

  async getPending(customerId: string, memberId?: string): Promise<Consent[]> {
    const params = new HttpParams({
      fromObject: {
        customerId,
        ...(memberId && { memberId }),
      },
    });
    return lastValueFrom(this.http.get<Consent[]>(`${environment.API_URL}/member_consents/pending`, { params }));
  }

  async createMultiple(memberId: string, consentIds: string[]) {
    const httpCall = this.http.post<MemberConsent[]>(`${environment.API_URL}/member_consents/create`, {
      memberId,
      consentIds,
    });
    return lastValueFrom(httpCall);
  }
}
