import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { JoinService } from 'src/app/utils/join.service';

import { isPlatformServer } from '@angular/common';
import { TrackerService } from 'src/app/utils/tracking/tracker.service';
import { TrackingEvent } from '@mightyhealth/library';
declare const branch: any;

@Component({
  selector: 'mighty-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.css'],
})
export class JoinComponent {
  showing = false;
  mobile = false;
  ios = false;
  isServer = false;
  context?: 'community' | 'lesson';
  constructor(
    joinService: JoinService,
    @Inject(PLATFORM_ID) platformId: Object,
    private trackerService: TrackerService
  ) {
    this.isServer = isPlatformServer(platformId);
    if (!this.isServer) {
      this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      this.ios = /iPad|iPhone|iPod/i.test(navigator.userAgent);
    }

    joinService.subscribe(() => {
      this.trackerService.track(TrackingEvent.JOIN_MODAL_DISPLAYED);
      this.showing = true;
      this.context = joinService.getContext();
      if (!this.isServer) {
        document.body.classList.add('hide-overflow');
      }
    });
  }

  hide() {
    this.trackerService.track(TrackingEvent.JOIN_MODAL_SKIPPED);
    this.showing = false;
    if (!this.isServer) {
      document.body.classList.remove('hide-overflow');
    }
  }
  openingLink() {
    this.trackerService.track(TrackingEvent.JOIN_MODAL_DOWNLOAD);
  }

  sendSms(phoneComponent: HTMLInputElement) {
    let phone = phoneComponent.value;
    if (!phone.startsWith('+')) {
      phone = '+1' + phone;
    }
    var linkData = {
      tags: [],
      channel: 'Website',
      feature: 'TextMeTheApp',
      data: {},
    };
    var options = {};
    var callback = (err: any) => {
      if (err) {
        alert('Sorry, something went wrong.');
        console.error(err);
      } else {
        this.trackerService.track(TrackingEvent.JOIN_MODAL_SMS_SENT);

        alert('SMS sent!');
      }
    };
    branch.sendSMS(phone, linkData, options, callback);
  }
}
