export enum WebTrackignEvents {
  ACTIVATION_FLOW_STARTED = 'Activation Flow Started',

  HEALTH_INSURANCE_CONTINUE = 'Health Insurance Page - Continue',

  VIEWED_HOME = 'Member home Viewed',
  VIEWED_PAYWALL = 'Paywall Viewed',

  WEB_SIGNUP = 'Web Signup',
  WEB_LOGIN_FINISHED = 'Web Login Finished',
  WEB_SCHEDULED = 'Web Appointment Scheduled',
}
