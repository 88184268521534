import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { RecaptchaVerifier } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Member } from '@mightyhealth/library';
import { MembersService } from 'src/app/apis/members.service';
import { AuthService } from 'src/app/utils/auth.service';
import { ToastService } from 'src/app/utils/toast.service';
import { TrackerService } from 'src/app/utils/tracking/tracker.service';
import { StorageService } from '../../utils/storage.service';
import { WebTrackignEvents } from 'src/app/utils/tracking/web-tracking-events';
import { RecaptchaFactoryService } from 'src/app/utils/recaptcha.service';

type AuthProvider = 'google.com' | 'facebook.com' | 'apple.com' | 'phone';

@Component({
  selector: 'mighty-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements AfterViewInit, OnDestroy {
  private recaptchaId = 'recaptcha';
  @Input() modalState = false;
  @Output() modalStateChange = new EventEmitter<boolean>();
  @Output() phoneConfimationFlow = new EventEmitter<boolean>();

  @Output() logged = new EventEmitter<boolean>();

  @ViewChild('recaptchaContainer') recaptchaContainer!: ElementRef;

  confirmationCode: string = '';
  retryConfirmationCode: boolean = false;

  errorMessage = '';

  loading = false;

  isPhoneValid = false;
  phone = '';

  phoneConfirmationTab = false;

  showLoginOptions = false;

  recaptcha?: RecaptchaVerifier;

  constructor(
    private authService: AuthService,
    private membersService: MembersService,
    private toastService: ToastService,
    private storageService: StorageService,
    private tracker: TrackerService,
    private router: Router,
    private recaptchaFactory: RecaptchaFactoryService
  ) {}

  ngAfterViewInit(): void {
    this.createRecaptcha();
  }

  ngOnDestroy(): void {
    this.recaptcha?.clear();
    this.recaptcha = undefined;
    this.recaptchaContainer.nativeElement.innerHTML = `<div id="${this.recaptchaId}"></div>`;
  }

  closeModal() {
    this.modalState = false;
    this.modalStateChange.emit(this.modalState);
    this.showLoginOptions = false;
    this.phoneConfirmationTab = false;
    this.phoneConfimationFlow.emit(this.phoneConfirmationTab);
    this.retryConfirmationCode = false;
  }

  private handleErrorMessage(error: HttpErrorResponse | Error) {
    let errorMessage = error.message;

    if (error instanceof HttpErrorResponse) {
      errorMessage =
        {
          401: 'Please, check your credentials or retry login method.',
          403: 'Invalid credentials. Please, register before logging in.',
        }[error.status] ?? 'An error ocurred. Please, try again.';
    }
    return errorMessage;
  }
  /**
   * This will be used as top level method to call the correct auth function
   * @param provider - Auth method
   * @returns null
   */
  async auth(provider: AuthProvider) {
    let member!: Member;
    this.loading = true;

    try {
      if (provider === 'phone') {
        this.phoneConfirmationTab = true;
        this.phoneConfimationFlow.emit(this.phoneConfirmationTab);
        await this.confirmPhoneVerificationCode();
      } else {
        await this.authService.oAuthLogin(provider);
      }

      this.tracker.track(WebTrackignEvents.WEB_LOGIN_FINISHED, { method: provider });
      this.logged.emit(true);
    } catch (error) {
      await this.toastService.addToast({
        message: this.handleErrorMessage(error as Error),
        type: 'error',
      });
    } finally {
      this.loading = false;
      this.phoneConfirmationTab = false;

      if (member) {
        await this.toastService.addToast({
          message: "You've successfully logged in.",
          type: 'success',
        });
      }
    }
  }

  async sendPhoneVerificationCode() {
    if (this.phone === '' || !this.isPhoneValid) return;
    this.loading = true;
    try {
      const prefixedPhone = `+1${this.phone}`;
      if (!this.recaptcha) {
        this.createRecaptcha();
      }
      await this.authService.getPhoneConfirmation(prefixedPhone, this.recaptcha as RecaptchaVerifier);
      this.phoneConfirmationTab = true;
      this.phoneConfimationFlow.emit(this.phoneConfirmationTab);
      return;
    } catch (error) {
      await this.toastService.addToast({
        message: this.handleErrorMessage(error as Error),
        type: 'error',
      });
    } finally {
      this.loading = false;
    }
  }

  async confirmPhoneVerificationCode() {
    if (this.confirmationCode === '' || this.confirmationCode.length !== 6) return;

    this.loading = true;
    try {
      await this.authService.confirmPhoneVerificationCode(this.confirmationCode);
      this.phoneConfirmationTab = false;
      this.phoneConfimationFlow.emit(this.phoneConfirmationTab);
    } catch (err) {
      throw err;
    } finally {
      this.loading = false;
    }
  }

  retryVerificationCodeSend() {
    this.phone = '';
    this.isPhoneValid = false;
    this.confirmationCode = '';
    this.retryConfirmationCode = true;
    this.phoneConfirmationTab = false;
    this.phoneConfimationFlow.emit(this.phoneConfirmationTab);
  }

  onPhoneChange(phoneNumber: string | null): void {
    this.isPhoneValid = !!phoneNumber && phoneNumber.length >= 10;
    this.phone = phoneNumber ? phoneNumber : '';
  }

  renderLoginOptions() {
    this.showLoginOptions = true;
  }

  goToOnboarding() {
    this.closeModal();
    this.router.navigate(['/onboarding']);
  }

  private createRecaptcha() {
    if (!this.recaptchaContainer.nativeElement.innerHTML) {
      this.recaptchaContainer.nativeElement.innerHTML = `<div id="${this.recaptchaId}"></div>`;
    }
    this.recaptcha = this.recaptchaFactory.create(this.recaptchaId);
  }
}
