import { Injectable } from '@angular/core';
import { FeatureFlagService } from './feature-flag.service';
import { Router } from '@angular/router';
import { FEATURE_FLAG } from './feature-flag.const';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private featureFlagService: FeatureFlagService, private router: Router) {}

  async goToHome(optionalRoute?: string[]) {
    if (await this.featureFlagService.getFeatureFlag(FEATURE_FLAG.NEW_WEBHOME)) this.router.navigate(['/home']);
    else this.router.navigate(optionalRoute || ['/exercises/list/programs']);
  }
}
