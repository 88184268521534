<header class="nav-header" [class.menu-open]="isMenuOpen" [class.section-screen]="currentRouteName">
  <ng-template [ngIf]="isMobileView" [ngIfElse]="desktopLogo">
    <div *ngIf="currentRouteName; else fullLogo" class="logo-sm">
      <a href="/">
        <img src="/webapp-assets/logo-v2-circle.svg" alt="logo-mobile" />
      </a>
    </div>
    <div class="current-page-name-sm">{{ currentRouteName }}</div>
    <ng-template #fullLogo>
      <a class="logo" routerLink="/" (click)="close()"> </a>
    </ng-template>
  </ng-template>
  <ng-template #desktopLogo>
    <a class="logo" [routerLink]="user?.name ? (canShowHome ? '/home' : 'exercises') : '/'" (click)="close()"> </a>
  </ng-template>

  <nav class="content" *ngIf="!isLandingPage && !isGetAppPage">
    <ul class="menu-items">
      <li *ngIf="!logged" (click)="subMenuToggle()">
        For Individuals
        <mighty-icon
          *ngIf="theme === 'transparent-light'; else blackDropdown"
          icon="arrow_down"
          color="white"></mighty-icon>
        <ng-template #blackDropdown>
          <mighty-icon icon="arrow_down"></mighty-icon>
        </ng-template>
      </li>
      <li *ngIf="!logged" routerLink="/enterprise" (click)="closeSubMenu()" routerLinkActive="page-active">
        For Health Plans
      </li>
      <li *ngIf="logged && canShowHome" routerLink="/home" routerLinkActive="page-active">Home</li>
      <li
        *ngIf="logged && hasContentAccessEntitlement"
        routerLink="/exercises/list/programs"
        routerLinkActive="page-active">
        Exercises
      </li>
      <li *ngIf="logged" routerLink="/events" routerLinkActive="page-active">Events</li>
      <li *ngIf="logged && hasContentAccessEntitlement" routerLink="/community" routerLinkActive="page-active">
        Community
      </li>
      <li *ngIf="!logged">
        <a target="_blank" href="https://mightyhealth.com/blog"> Blog </a>
      </li>
      <li *ngIf="!logged" routerLink="/about-us" routerLinkActive="page-active" (click)="closeSubMenu()">About Us</li>
      <nav class="nav-submenu menu" [class.open]="isSubMenuOpen">
        <ul>
          <li
            *ngIf="logged && canShowHome"
            (click)="closeSubMenu()"
            routerLink="/home"
            routerLinkActive="submenu-active">
            Home
          </li>
          <li
            *ngIf="hasContentAccessEntitlement"
            (click)="closeSubMenu()"
            routerLink="/exercises"
            routerLinkActive="submenu-active">
            Exercises
          </li>
          <li (click)="closeSubMenu()" routerLink="/events" routerLinkActive="submenu-active"><a> Events </a></li>
          <li
            *ngIf="logged && hasContentAccessEntitlement"
            (click)="closeSubMenu()"
            routerLink="/community"
            routerLinkActive="submenu-active">
            <a> Community </a>
          </li>
        </ul>
      </nav>
    </ul>
  </nav>

  <ng-template [ngIf]="!logged && !isLandingPage" [ngIfElse]="userLoggedIn">
    <nav class="actions">
      <ng-template [ngIf]="!isLandingPage">
        <ng-template [ngIf]="!loading" [ngIfElse]="navLoading">
          <mighty-button (click)="openLogin()" data-cy="log-in"><span class="sign-in-text">Log In</span></mighty-button>
          <mighty-button (click)="goToPreOnboarding()" data-cy="get-started">Get Started</mighty-button>
        </ng-template>
        <ng-template #navLoading>
          <span class="nav-loader"></span>
        </ng-template>
      </ng-template>
    </nav>
    <mighty-context-menu
      *ngIf="!loading; else mobileLoading"
      class="mobile-nav"
      [userName]="user.name"
      [show]="isMenuOpen"
      [profilePhoto]="user.photoUrl"
      (click)="menuToggle()">
    </mighty-context-menu>
    <ng-template #mobileLoading>
      <span class="nav-loader loader-sm"></span>
    </ng-template>
  </ng-template>

  <ng-template #userLoggedIn>
    <mighty-context-menu
      *ngIf="!isLandingPage"
      [userName]="user.name"
      [profilePhoto]="user.photoUrl"
      [show]="isMenuOpen"
      [isLoggedIn]="logged"
      (click)="menuToggle()">
    </mighty-context-menu>
  </ng-template>

  <div class="header-menu menu" [class.open]="isMenuOpen" [class.logged-out]="!logged">
    <main>
      <ul class="menu-items">
        <li *ngIf="logged && canShowHome">
          <a (click)="close()" routerLink="/home" routerLinkActive="page-active"> Home </a>
        </li>
        <li *ngIf="!logged">
          <a (click)="close()" [routerLinkActiveOptions]="{ exact: true }" routerLink="" routerLinkActive="page-active">
            For Individuals</a
          >
        </li>
        <li *ngIf="!logged">
          <a (click)="close()" routerLink="/enterprise" routerLinkActive="page-active"> For Health Plans </a>
        </li>
        <li *ngIf="logged && hasContentAccessEntitlement">
          <a (click)="close()" routerLink="/exercises" routerLinkActive="page-active"> Exercises </a>
        </li>
        <li>
          <a (click)="close()" routerLink="/events" routerLinkActive="page-active"> Events </a>
        </li>
        <li *ngIf="logged && hasContentAccessEntitlement">
          <a (click)="close()" routerLink="/community" routerLinkActive="page-active"> Community </a>
        </li>
        <li *ngIf="!logged">
          <a (click)="close()" routerLink="/about-us" routerLinkActive="page-active"> About Us </a>
        </li>
        <li *ngIf="!logged">
          <a target="_blank" href="https://mightyhealth.com/blog" (click)="close()"> Blog </a>
        </li>
      </ul>
    </main>
    <div class="menu-footer">
      <span *ngIf="user.name" class="logged-user-name">{{ user.name }}</span>
      <div class="auth-actions-container">
        <ng-template [ngIf]="!logged" [ngIfElse]="logOutContainer">
          <mighty-button expand="full" fill="outline" (click)="openLogin()"
            ><span class="sign-in-text">Log In</span></mighty-button
          >
          <mighty-button expand="full" (click)="openLogin()">Sign Up </mighty-button>
        </ng-template>
        <ng-template #logOutContainer>
          <mighty-button expand="full" fill="outline" (click)="logout()">
            <span class="sign-in-text">Log out</span>
          </mighty-button>
        </ng-template>
      </div>
      <ul>
        <li *ngIf="logged" class="small-log-out" (click)="logout()">Log Out</li>
      </ul>
    </div>
  </div>
</header>
