import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { ReplaySubject } from 'rxjs';

export interface MightyMetaTags {
  description?: string;
  title?: string;
  image?: string;
  custom?: MetaDefinition[];
  canonical?: string;
}

@Injectable({
  providedIn: 'root',
})
export class MetaTagsService {
  sub = new ReplaySubject<MightyMetaTags>();

  constructor(private metaService: Meta, @Inject(DOCUMENT) private doc: any) {
    this.sub.subscribe((metatags: MightyMetaTags) => this.upsertDefault(metatags));
  }

  setMetaTags(metaTags: MightyMetaTags) {
    this.sub.next(metaTags);
  }

  remove(tag: string) {
    this.metaService.getTags("property='" + tag + "'").forEach(element => this.metaService.removeTagElement(element));
  }

  cleanUp() {
    const tagsToRemove = [...this.metaService.getTags('property')];
    tagsToRemove.forEach(tag => this.metaService.removeTagElement(tag));
  }

  upsert(tag: MetaDefinition) {
    if (tag.property) {
      this.remove(tag.property);
      this.metaService.addTag(tag);
    }
  }

  bulkUpsert(tags: MetaDefinition[]) {
    tags.forEach(tag => {
      if (tag.property) this.remove(tag.property);
    });
    this.metaService.addTags(tags);
  }

  upsertDefault(metaTags: MightyMetaTags) {
    const tags: MetaDefinition[] = [];
    if (metaTags.image) {
      this.upsertImage(metaTags.image);
    }

    if (metaTags.description) {
      tags.push({
        name: 'description',
        property: 'og:description',
        content: metaTags.description.slice(0, 130),
      });
    }

    if (metaTags.title) {
      tags.push({
        name: 'title',
        property: 'og:title',
        content: metaTags.title.slice(0, 50),
      });
    }

    if (metaTags.canonical) {
      this.createLinkForCanonicalURL(metaTags.canonical);
    }

    this.bulkUpsert([...tags, ...(metaTags.custom ?? [])]);
  }

  upsertImage(imageUrl: string) {
    this.upsert({ name: 'image', property: 'og:image', content: imageUrl });
    this.upsert({
      name: 'image:secure_url',
      property: 'og:image:secure_url',
      content: imageUrl,
    });
  }

  createLinkForCanonicalURL(canonicalPath?: string) {
    const canonicalUrl = canonicalPath
      ? `https://${this.doc.location.hostname}/${canonicalPath}`
      : this.doc.URL.replace('http://', 'https://');

    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.doc.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', canonicalUrl);
  }
}
