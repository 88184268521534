import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { MembersService } from '../apis/members.service';
import { OnboardingExperimentService } from '../services/onboarding-experiment.service';
import { OnboardingControllerService } from '../utils/onboarding/onboarding-controller.service';
import { OnboardingStepsService } from '../utils/onboarding/onboarding-steps-builder.service';
import { ExperimentService } from '../utils/experiment.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuard {
  constructor(
    private memberService: MembersService,
    private onboardingControllerService: OnboardingControllerService,
    private onboardingStepsService: OnboardingStepsService,
    private onboardingExperimentService: OnboardingExperimentService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    if (this.onboardingControllerService.initialized) {
      return true;
    }

    let forceVariants = undefined;
    const variantQueryParams = route.queryParams['variant'];

    if (variantQueryParams !== undefined)
      forceVariants = Array.isArray(variantQueryParams) ? variantQueryParams : [variantQueryParams];

    const variant = await this.onboardingExperimentService.startOnboardingExperiment(forceVariants);
    let routes = variant.route;

    const startFrom = route.firstChild?.children[0].routeConfig?.path;
    const member = await this.memberService.getCurrentMember();
    if (member) {
      routes = routes.filter(route => !['date-of-birth', 'signup'].includes(route));
    }

    const steps = this.onboardingStepsService.getSteps(routes);

    await this.onboardingControllerService.init(steps, startFrom);

    return true;
  }
}

export const prepareOnboardingGuard = (route: ActivatedRouteSnapshot) => inject(OnboardingGuard).canActivate(route);
