<ng-container>
  <div class="slide">
    <div class="slider">
      <div class="content flex items-center flex-col justify-center gap-[1rem]">
        <h3>Oops!</h3>
        <h4>The link is invalid or expired. <br /></h4>
        <mighty-button (click)="navigateHome()">Go to Homepage</mighty-button>
      </div>
    </div>
  </div>
</ng-container>
