import { inject, Injectable } from '@angular/core';
import { TrackingEventV2 } from '@mightyhealth/library';
import { Subject } from 'rxjs';
import { CHRONIC_CONDITIONS_KEY_PATH_MAP } from 'src/app/pages/onboarding-v2/chronic-conditions.const';
import { OnboardingService } from '../onboarding.service';
import { TrackerService } from '../tracking/tracker.service';
import { OnboardingStep, OnboardingStepEvent, OnboardingStepsService } from './onboarding-steps-builder.service';

type StepOperationCommand = 'before' | 'after';

@Injectable({
  providedIn: 'root',
})
export class OnboardingControllerService {
  initialized = false;
  private currentStep!: OnboardingStep;
  private currentStepIndex = 0;
  private steps: OnboardingStep[] = [];
  private staticSteps: OnboardingStep[] = [];
  private stepSub: Subject<OnboardingStepEvent> = new Subject();
  private onCompletionSub: Subject<void> = new Subject();

  tracker = inject(TrackerService);

  constructor(private onboardingStepsService: OnboardingStepsService, private onboardingService: OnboardingService) {}

  get currentPosition() {
    return this.currentStep?.position + 1;
  }

  get size() {
    return this.steps.length;
  }

  get stepPaths() {
    return this.steps.map(step => step.path);
  }

  get activeStep() {
    return this.currentStep;
  }

  next(timeout?: number) {
    const currentStep = this.steps.find(step => step.path === this.currentStep?.path);
    if (currentStep?.next) {
      const nextIndex = this.stepPaths.indexOf(currentStep.next);
      this.currentStep = this.steps[nextIndex];
      this.currentStepIndex = nextIndex;
      return setTimeout(() => {
        this.stepSub.next({
          step: this.currentStep,
        });
      }, timeout);
    }

    return this.onCompletionSub.next();
  }

  prev() {
    const currentStep = this.steps.find(step => step.path === this.currentStep?.path);
    if (currentStep?.prev) {
      const prevIndex = this.stepPaths.indexOf(currentStep.prev);
      this.currentStep = this.steps[prevIndex];
      this.currentStepIndex = prevIndex;
      this.stepSub.next({
        step: this.currentStep,
      });
    }
  }

  getSteps() {
    return this.steps;
  }

  async init(steps: OnboardingStep[], startFrom?: string) {
    if (startFrom) {
      const startFromIndex = steps.findIndex(step => step.path === startFrom);
      this.currentStepIndex = startFromIndex;
    }
    this.staticSteps = steps;
    this.steps = steps;
    this.currentStep = steps[this.currentStepIndex];
    this.initialized = true;

    await this.tracker.track(TrackingEventV2.Onboarding.ONBOARDING_STARTED, { flow: 'main' });

    return this.currentStep;
  }

  onStep(next: (value: OnboardingStepEvent) => void) {
    return this.stepSub.subscribe(next);
  }

  onComplete(next: () => void) {
    return this.onCompletionSub.subscribe(next);
  }

  manageChronicConditionsSteps(paths: string[]) {
    const chronicConditions = Object.values(CHRONIC_CONDITIONS_KEY_PATH_MAP);
    const uniquePaths = [...new Set(paths)];
    const chronicConditionsIndex = this.steps.findIndex(step => step.path === 'chronic-conditions') + 1;
    const stepPaths = [...this.stepPaths.filter(path => !chronicConditions.includes(path))];
    stepPaths.splice(chronicConditionsIndex, 0, ...uniquePaths);
    const newSteps = this.onboardingStepsService.getSteps(stepPaths);
    this.steps = newSteps;

    this.onboardingService.updateChronicConditionsAnswers();
  }

  restart() {
    this.reset();
    this.stepSub.next({ step: this.currentStep });
  }

  reset() {
    this.onboardingService.resetOnboarding();
    this.onboardingService.resetAllOnboarding();
    this.currentStepIndex = 0;
    this.steps = [...this.staticSteps];
    this.currentStep = this.steps[this.currentStepIndex];
  }

  addStep(step: string) {
    return {
      before: (before: string) => {
        this.addStepCommand('before', step, before);
      },
      after: (after: string) => {
        this.addStepCommand('after', step, after);
      },
    };
  }

  removeSteps(steps: string[]) {
    const stepPaths = this.stepPaths.filter(step => !steps.includes(step));
    const newSteps = this.onboardingStepsService.getSteps(stepPaths);
    this.steps = newSteps;
  }

  setCurrentStep(stepPath: string) {
    if (this.activeStep?.path === stepPath) {
      return;
    }
    const stepIndex = this.stepPaths.findIndex(step => step === stepPath);
    if (stepIndex === -1) {
      return;
    }
    this.currentStep = this.steps[stepIndex];
    this.currentStepIndex = stepIndex;
    this.stepSub.next({
      step: this.currentStep,
    });
  }

  private addStepCommand(operation: StepOperationCommand, newStepPath: string, targetStepPath: string) {
    const targetIdx = this.steps.findIndex(path => path.path === targetStepPath);
    if (targetIdx === -1) {
      return;
    }
    const stepAlreadyInserted = this.stepPaths.some(path => path === newStepPath);
    if (stepAlreadyInserted) {
      console.log('step already exist!', newStepPath);
      return;
    }
    const newIndex = operation === 'before' ? targetIdx : targetIdx + 1;
    const stepPaths = [...this.stepPaths];
    stepPaths.splice(newIndex, 0, newStepPath);

    const newSteps = this.onboardingStepsService.getSteps(stepPaths);
    console.log(`Step "${newStepPath}" inserted ${operation} "${targetStepPath}"`);
    this.steps = newSteps;
  }

  updateStepsForOptum() {
    this.removeSteps(['health-insurance']);
    this.addStep('health-insurance').before('signup');
  }

  updateStepsForMembershipList() {
    this.removeSteps(['health-insurance']);
    this.addStep('health-insurance').after('signup');
  }
}
