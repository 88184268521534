import { EnvironmentName } from './environment-name';
import { EnvironmentDef } from './environments';

export const environment: EnvironmentDef = {
  production: true,
  environmentName: EnvironmentName.DEMO,
  API_URL: 'https://api.demo.mightyhealth.com/v2/auth',
  API_UNAUTH_URL: 'https://api.demo.mightyhealth.com',
  API_UNAUTH_LEGACY_URL: 'https://api.demo.mightyhealth.com/v2/unauth',
  BASE_URL: 'https://demo.mightyhealth.com',
  SEGMENT_KEY: 'FFhtXHZMBNvsToX1WhUKcObHKXnYYfNx',
  STRIPE_KEY: 'pk_test_yfPZfq6NXH53N3Lz3n1Ap8XX002L1Wh9SU',
  GA_MEASUREMENT_ID: 'G-QJ069YQJ0J',
  firebase: {
    apiKey: 'AIzaSyC_MYWqCHhzU6ncMiMZGJqrESFa01ooGhw',
    authDomain: 'mighty-health.firebaseapp.com',
    databaseURL: 'https://mighty-health.firebaseio.com',
    projectId: 'mighty-health',
    storageBucket: 'mighty-health.appspot.com',
    messagingSenderId: '1055317642538',
    appId: '1:1055317642538:web:ec50beca4b26b20b10e253',
    measurementId: 'G-5WVSE21TQL',
  },
  KLAVIYO_CONFIG: {
    url: 'https://a.klaviyo.com/api',
    publicApiKey: 'YkpiqZ',
  },
  SENTRY_DSN: 'https://59d3f8cb639040c6aa756a7e6a0159c9@o350264.ingest.sentry.io/4504476181659648',
  ACUITY_APPT_TYPE: '59192930',
  BRIDGE_CONFIG: {
    PUB_KEY: 'b896d040-abb2-40ed-9acb-b46e247b6d65',
    INTAKE_CALL_SERVICE_TYPE_ID: 'svt_mighty_visit',
  },
};
