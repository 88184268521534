import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
import { Router } from '@angular/router';
import {
  BookingWallActivationProgress,
  CommunityMember,
  Member,
  MemberExercisesPreferences,
  MemberOnboardingProgress,
  SignUpModel,
} from '@mightyhealth/library';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { first, firstValueFrom, lastValueFrom, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../utils/auth.service';
import { CustomerService } from '../utils/customer.service';
import { OnboardingService } from '../utils/onboarding.service';
import { StorageService } from '../utils/storage.service';

dayjs.extend(utc);
dayjs.extend(timezone);

@Injectable({
  providedIn: 'root',
})
export class MembersService {
  private referralMemberSubscription: ReplaySubject<CommunityMember | undefined> = new ReplaySubject(1);
  private currentMember: ReplaySubject<Member | undefined> = new ReplaySubject(1);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private storage: StorageService,
    private customerService: CustomerService,
    private onboardingService: OnboardingService
  ) {
    this.authService.subscribe(async (user: User | null) => {
      if (user && !user.isAnonymous && (await user.getIdTokenResult(true)).claims['memberId']) {
        await this.me();
      } else {
        this.currentMember.next(undefined);
      }
    });
  }

  async setCurrentMember(member: Member) {
    this.currentMember.next(member);
  }

  async create(model: SignUpModel): Promise<Member> {
    model.timeZone = dayjs.tz.guess();
    model.insuranceInfo = this.customerService.updateInsuranceInfo(model?.insuranceInfo);
    model.chronicConditions = this.onboardingService.getMappedChronicConditions();

    const member = await lastValueFrom(this.http.post<Member>(`${environment.API_URL}/signup`, model));
    this.currentMember.next(member);
    return member;
  }

  async createOrLogin(model = {} as SignUpModel): Promise<Member> {
    let member;
    try {
      member = await this.create(model);
    } catch (error) {
      member = await this.me();
    }
    this.currentMember.next(member);
    return member;
  }

  async get(memberId: string): Promise<CommunityMember> {
    return await lastValueFrom(this.http.get<CommunityMember>(`${environment.API_URL}/members/${memberId}`));
  }

  async me(): Promise<Member> {
    const member = await lastValueFrom(this.http.get<Member>(`${environment.API_URL}/me`));
    this.currentMember.next(member);
    return member;
  }

  subscribe(next: (value: Member | undefined) => void) {
    return this.currentMember.subscribe(next);
  }

  async subscribeOnce(next: (value: Member | undefined) => void) {
    return this.currentMember.pipe(first()).subscribe(next);
  }

  async getCurrentMember(): Promise<Member | undefined> {
    return firstValueFrom(this.currentMember);
  }

  async getReferralMember(next: (value: CommunityMember | undefined) => void) {
    return this.referralMemberSubscription.subscribe(next);
  }
  async setReferralMember(member: CommunityMember | undefined) {
    this.referralMemberSubscription.next(member);
  }

  async logout(navigate = true) {
    this.currentMember.next(undefined);
    await this.authService.logout();
    this.storage.clear();
    if (navigate) {
      await this.router.navigate(['/']);
    }
  }

  async setExercisesPreferences(payload: MemberExercisesPreferences) {
    const memberUpdated = await lastValueFrom(
      this.http.put<Member>(`${environment.API_URL}/me/exercises-preferences`, payload)
    );
    this.currentMember.next(memberUpdated);
    return memberUpdated;
  }

  async updateMember(payload: Member) {
    const memberUpdated = await lastValueFrom(this.http.post<Member>(`${environment.API_URL}/me`, payload));
    this.currentMember.next(memberUpdated);
    return memberUpdated;
  }

  async getMemberWebActivationProgress(): Promise<MemberOnboardingProgress> {
    return await lastValueFrom(
      this.http.get<MemberOnboardingProgress>(`${environment.API_URL}/me/activation-progress`)
    );
  }

  async getClinicActivationProgress(): Promise<BookingWallActivationProgress> {
    return lastValueFrom(
      this.http.get<BookingWallActivationProgress>(`${environment.API_URL}/me/activation-progress/clinic`)
    );
  }

  async updatePersonalInfo(data: Partial<Member>): Promise<Member> {
    const member = await lastValueFrom(this.http.patch<Member>(`${environment.API_URL}/me/personal_info`, data));
    this.currentMember.next(member);
    return member;
  }

  async getClinicActivationStatus() {
    return lastValueFrom(
      this.http.get<{ completed: boolean }>(`${environment.API_URL}/me/activation-progress/clinic/status`)
    );
  }
}
