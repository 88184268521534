import { Injectable } from '@angular/core';
import { Member } from '@mightyhealth/library';
import { SHA1 } from 'crypto-js';
import Stripe from 'stripe';
import { MembersService } from '../apis/members.service';

declare let ire: any;

export interface ImpactConversionTrack {
  customer: string | Stripe.Customer | Stripe.DeletedCustomer;
  promotionCode?: string | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class ImpactService {
  constructor(private memberService: MembersService) {}

  async sendConversion({ customer, promotionCode }: ImpactConversionTrack): Promise<void> {
    try {
      const member = await this.memberService.me();

      if (!member) return;

      ire('identify', {
        customerid: member.id,
      });
      const email = member.email || member.unverifiedEmail;
      ire('trackConversion', 27338, {
        customerEmail: email ? SHA1(email).toString() : '',
        customerId: member.id,
        orderId: customer,
        OrderPromoCode: promotionCode,
      });
    } catch (e) {
      console.error('ire error', e);
    }
  }
}
