import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Member } from '@mightyhealth/library';
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { Environments } from '../../../environments/environments';
import { KlaviyoService } from '../../apis/klaviyo.service';
import { StorageService } from '../storage.service';

export type PropertiesObject = Record<string, any>;

@Injectable({
  providedIn: 'root',
})
export class TrackerService {
  readonly googleClickIdStorageKey = 'GOOGLE_GCLID';
  readonly googleClickIdQueryParam = 'gclid';

  private analytics?: Analytics;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private klaviyo: KlaviyoService,
    private route: ActivatedRoute,
    private storage: StorageService
  ) {}

  private async forceReload() {
    if (!this.analytics && !isPlatformServer(this.platformId) && Environments.isProd()) {
      await AnalyticsBrowser.load({ writeKey: Environments.getProp('SEGMENT_KEY') as string }).then(
        ([analytics, _]) => (this.analytics = analytics)
      );

      this.route.queryParams.subscribe(async params => {
        if (params[this.googleClickIdQueryParam]) {
          await this.storage.set(this.googleClickIdStorageKey, params[this.googleClickIdQueryParam]);
        }
      });
    }
  }

  async track(eventName: string, properties?: PropertiesObject) {
    try {
      if (!Environments.isProd()) return;
      await this.forceReload();
      properties = await this.setGCLID(properties);
      await this.analytics?.track(eventName, {
        application: `webapp${Environments.isProd() ? '' : '-' + Environments.getProp('environmentName')}`,
        ...properties,
      });
      await this.klaviyo.track(eventName, {
        application: `webapp${Environments.isProd() ? '' : '-' + Environments.getProp('environmentName')}`,
        ...properties,
      });
    } catch (error) {
      console.log('🚀 ~ TrackerService ~ track ~ error:', error);
    } finally {
      return;
    }
  }

  async addAnonymousTraits(newTraits: any) {
    if (!Environments.isProd()) return;
    await this.forceReload();
    await this.klaviyo.addTraits(newTraits);
    await this.analytics?.identify(undefined, newTraits);
  }

  async identify(member?: Member, traits?: any) {
    try {
      if (!Environments.isProd()) return;
      await this.forceReload();
      if (member) {
        await this.klaviyo.identify(member);
      }
      if (this.analytics) {
        await this.analytics.identify(member?.id, traits);
      }
    } catch (e) {
      console.log('🚀 ~ TrackerService ~ identify ~ e:', e);
    }
  }

  async page(title: string | undefined) {
    if (!Environments.isProd()) return;
    await this.forceReload();
    return this.analytics?.page(undefined, title, {
      path: (globalThis as any).window.location.pathname,
      url: (globalThis as any).window.location.href,
    });
  }

  async setGCLID(properties?: any) {
    const enrichedProperties = { ...properties };
    const gclid = await this.storage.get(this.googleClickIdStorageKey);
    if (gclid) {
      enrichedProperties[this.googleClickIdQueryParam] = gclid;
    }
    return enrichedProperties;
  }
}
