import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InsuranceInfo, MemberCustomer } from '@mightyhealth/library';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MemberCustomerService {
  constructor(private http: HttpClient) {}

  async saveInsuranceInfo<T>(insuranceInfo: InsuranceInfo<T>): Promise<string> {
    return await lastValueFrom(this.http.post<string>(`${environment.API_URL}/member_customer/create`, insuranceInfo));
  }

  async saveMemberCustomer<T>(insuranceInfo: InsuranceInfo<T>): Promise<string> {
    return await lastValueFrom(this.http.post<string>(`${environment.API_URL}/member_customer`, insuranceInfo));
  }

  async getLastMemberCustomer(): Promise<MemberCustomer | null> {
    try {
      return await lastValueFrom(this.http.get<MemberCustomer>(`${environment.API_URL}/member_customer/last`));
    } catch (e: any) {
      if (e?.status === 404) return null;
      throw e;
    }
  }
}
