abstract class AuthException extends Error {
  errorData: any;
  constructor(message?: string, data = {}) {
    super(message);
    this.errorData = data;
  }
}

export class GetPhoneConfirmationException extends AuthException {
  constructor(message?: string, data = {}) {
    super(message, data);
    this.name = 'GetPhoneConfirmationException';
  }
}

export class PhoneConfirmationFailedException extends AuthException {
  constructor(message?: string, data = {}) {
    super(message, data);
    this.name = 'PhoneConfirmationFailedException';
  }
}
